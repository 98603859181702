import { FormatQualifier } from "./format/FormatQualifier.js";
/**
 * @description Contains functions to select the asset format, can be used to convert images and videos to other formats.
 * @memberOf Qualifiers
 * @namespace Format
 * @see Visit {@link Actions.Delivery.format|Delivery Format} for an example
 */
/**
 * @summary qualifier
 * @description Image format heic.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function heic() {
  return new FormatQualifier('heic');
}
/**
 * @summary qualifier
 * @description Image format flif.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function flif() {
  return new FormatQualifier('flif');
}
/**
 * @summary qualifier
 * @description Image format ai.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function ai() {
  return new FormatQualifier('ai');
}
/**
 * @summary qualifier
 * @description Image format wdp.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function wdp() {
  return new FormatQualifier('wdp');
}
/**
 * @summary qualifier
 * @description Image format svg.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function svg() {
  return new FormatQualifier('svg');
}
/**
 * @summary qualifier
 * @description Image format webp.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function webp() {
  return new FormatQualifier('webp');
}
/**
 * @summary qualifier
 * @description Image format psd.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function psd() {
  return new FormatQualifier('psd');
}
/**
 * @summary qualifier
 * @description Image format jp2.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function jp2() {
  return new FormatQualifier('jp2');
}
/**
 * @summary qualifier
 * @description Image format jpc.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function jpc() {
  return new FormatQualifier('jpc');
}
/**
 * @summary qualifier
 * @description Image format eps.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function eps() {
  return new FormatQualifier('eps');
}
/**
 * @summary qualifier
 * @description Image format tiff.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function tiff() {
  return new FormatQualifier('tiff');
}
/**
 * @summary qualifier
 * @description Image format pdf.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function pdf() {
  return new FormatQualifier('pdf');
}
/**
 * @summary qualifier
 * @description Image format ico.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function ico() {
  return new FormatQualifier('ico');
}
/**
 * @summary qualifier
 * @description Image format bmp.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function bmp() {
  return new FormatQualifier('bmp');
}
/**
 * @summary qualifier
 * @description Image format png.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function png() {
  return new FormatQualifier('png');
}
/**
 * @summary qualifier
 * @description Image format gif.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function gif() {
  return new FormatQualifier('gif');
}
/**
 * @summary qualifier
 * @description Image format auto.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function auto() {
  return new FormatQualifier('auto');
}
/**
 * @summary qualifier
 * @description Image format jpg.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function jpg() {
  return new FormatQualifier('jpg');
}
/**
 * @summary qualifier
 * @description Image format djvu.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function djvu() {
  return new FormatQualifier('djvu');
}
/**
 * @summary qualifier
 * @description Image format ps.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function ps() {
  return new FormatQualifier('ps');
}
/**
 * @summary qualifier
 * @description Image format ept.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function ept() {
  return new FormatQualifier('ept');
}
/**
 * @summary qualifier
 * @description Image format eps3.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function eps3() {
  return new FormatQualifier('eps3');
}
/**
 * @summary qualifier
 * @description Image format fxb.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function fxb() {
  return new FormatQualifier('fxb');
}
/**
 * @summary qualifier
 * @description Image format gltf.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function gltf() {
  return new FormatQualifier('gltf');
}
/**
 * @summary qualifier
 * @description Image format heif.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function heif() {
  return new FormatQualifier('heif');
}
/**
 * @summary qualifier
 * @description Image format indd.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function indd() {
  return new FormatQualifier('indd');
}
/**
 * @summary qualifier
 * @description Image format jpe.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function jpe() {
  return new FormatQualifier('jpe');
}
/**
 * @summary qualifier
 * @description Image format jpeg.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function jpeg() {
  return new FormatQualifier('jpeg');
}
/**
 * @summary qualifier
 * @description Image format jxr.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function jxr() {
  return new FormatQualifier('jxr');
}
/**
 * @summary qualifier
 * @description Image format hdp.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function hdp() {
  return new FormatQualifier('hdp');
}
/**
 * @summary qualifier
 * @description Image format spd.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function spd() {
  return new FormatQualifier('spd');
}
/**
 * @summary qualifier
 * @description Image format arw.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function arw() {
  return new FormatQualifier('arw');
}
/**
 * @summary qualifier
 * @description Image format cr2.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function cr2() {
  return new FormatQualifier('cr2');
}
/**
 * @summary qualifier
 * @description Image format tga.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function tga() {
  return new FormatQualifier('tga');
}
/**
 * @summary qualifier
 * @description Image format tif.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function tif() {
  return new FormatQualifier('tif');
}
/**
 * @summary qualifier
 * @description Image format avif.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function avif() {
  return new FormatQualifier('avif');
}
/**
 * @summary qualifier
 * @description format usdz.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function usdz() {
  return new FormatQualifier('usdz');
}
/**
 * @summary qualifier
 * @description Image format 3g2.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function video3g2() {
  return new FormatQualifier('3g2');
}
/**
 * @summary qualifier
 * @description Image format 3gp.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function video3gp() {
  return new FormatQualifier('3gp');
}
/**
 * @summary qualifier
 * @description Image format avi.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoAvi() {
  return new FormatQualifier('avi');
}
/**
 * @summary qualifier
 * @description Image format flv.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoFlv() {
  return new FormatQualifier('flv');
}
/**
 * @summary qualifier
 * @description Image format m3u8.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoM3u8() {
  return new FormatQualifier('m3u8');
}
/**
 * @summary qualifier
 * @description Image format ts.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoTs() {
  return new FormatQualifier('ts');
}
/**
 * @summary qualifier
 * @description Image format mov.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoMov() {
  return new FormatQualifier('mov');
}
/**
 * @summary qualifier
 * @description Image format mkv.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoMkv() {
  return new FormatQualifier('mkv');
}
/**
 * @summary qualifier
 * @description Image format mp4.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoMp4() {
  return new FormatQualifier('mp4');
}
/**
 * @summary qualifier
 * @description Image format mpeg.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoMpeg() {
  return new FormatQualifier('mpeg');
}
/**
 * @summary qualifier
 * @description Image format mpd.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoMpd() {
  return new FormatQualifier('mpd');
}
/**
 * @summary qualifier
 * @description Image format mxf.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoMxf() {
  return new FormatQualifier('mxf');
}
/**
 * @summary qualifier
 * @description Image format ogv.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoOgv() {
  return new FormatQualifier('ogv');
}
/**
 * @summary qualifier
 * @description Image format webm.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoWebm() {
  return new FormatQualifier('webm');
}
/**
 * @summary qualifier
 * @description Image format wmv.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoWmv() {
  return new FormatQualifier('wmv');
}
/**
 * @summary qualifier
 * @description Image format m2ts.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoM2ts() {
  return new FormatQualifier('m2ts');
}
/**
 * @summary qualifier
 * @description Image format mts.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function videoMts() {
  return new FormatQualifier('mts');
}
/**
 * @summary qualifier
 * @description Audio format aac.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function audioAac() {
  return new FormatQualifier('aac');
}
/**
 * @summary qualifier
 * @description Audio format aiff.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function audioAiff() {
  return new FormatQualifier('aiff');
}
/**
 * @summary qualifier
 * @description Audio format amr.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function audioAmr() {
  return new FormatQualifier('amr');
}
/**
 * @summary qualifier
 * @description Audio format flac.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function audioFlac() {
  return new FormatQualifier('flac');
}
/**
 * @summary qualifier
 * @description Audio format m4a.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function audioM4a() {
  return new FormatQualifier('m4a');
}
/**
 * @summary qualifier
 * @description Audio format mp3.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function audioMp3() {
  return new FormatQualifier('mp3');
}
/**
 * @summary qualifier
 * @description Audio format ogg.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function audioOgg() {
  return new FormatQualifier('ogg');
}
/**
 * @summary qualifier
 * @description Audio format opus.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function audioOpus() {
  return new FormatQualifier('opus');
}
/**
 * @summary qualifier
 * @description Audio format wav.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function audioWav() {
  return new FormatQualifier('wav');
}
/**
 * @summary qualifier
 * @description Image format glb.
 * @memberOf Qualifiers.Format
 * @return {Qualifiers.Format.FormatQualifier}
 */
function glb() {
  return new FormatQualifier('glb');
}
const Format = {
  usdz,
  jp2,
  ai,
  auto,
  bmp,
  eps,
  flif,
  gif,
  heic,
  ico,
  jpc,
  jpg,
  pdf,
  png,
  psd,
  svg,
  tiff,
  wdp,
  webp,
  arw,
  audioAac,
  audioAiff,
  audioAmr,
  audioFlac,
  audioM4a,
  audioMp3,
  audioOgg,
  audioOpus,
  audioWav,
  avif,
  cr2,
  djvu,
  eps3,
  ept,
  fxb,
  gltf,
  hdp,
  heif,
  indd,
  jpe,
  jpeg,
  jxr,
  ps,
  spd,
  tga,
  tif,
  video3g2,
  video3gp,
  videoAvi,
  videoFlv,
  videoM2ts,
  videoM3u8,
  videoMkv,
  videoMov,
  videoMp4,
  videoMpd,
  videoMpeg,
  videoMts,
  videoMxf,
  videoOgv,
  videoTs,
  videoWebm,
  videoWmv,
  glb
};
export { usdz, jp2, ai, auto, bmp, eps, flif, gif, heic, ico, jpc, jpg, pdf, png, psd, svg, tiff, wdp, webp, arw, audioAac, audioAiff, audioAmr, audioFlac, audioM4a, audioMp3, audioOgg, audioOpus, audioWav, avif, cr2, djvu, eps3, ept, fxb, gltf, hdp, heif, indd, jpe, jpeg, jxr, ps, spd, tga, tif, video3g2, video3gp, videoAvi, videoFlv, videoM2ts, videoM3u8, videoMkv, videoMov, videoMp4, videoMpd, videoMpeg, videoMts, videoMxf, videoOgv, videoTs, videoWebm, videoWmv, glb, Format };